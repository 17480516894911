import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '../../../hooks/useAxios';
import { Table, Spin, Button, Modal, Select, notification } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const CurrencySettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);

  const {
    data: businessCurrencies,
    loading: businessCurrenciesLoading,
    refetch: getBusinessCurrencies,
  } = useLazyQuery(`/business/currency/list`, { method: 'GET' });

  const {
    data: allCurrencies,
    loading: allCurrenciesLoading,
    refetch: getAllCurrencies,
  } = useLazyQuery(`/global/currencies`, { method: 'GET' });

  const { refetch: createCurrency, loading: createLoading } = useLazyQuery(`/business/currency/create`, {
    method: 'POST',
  });

  useEffect(() => {
    getBusinessCurrencies();
    getAllCurrencies();
  }, []);

  const columns = [
    {
      title: 'Para Birimi',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Kodu',
      dataIndex: 'currency',
      key: 'currency',
      width: '100px',
    },
  ];

  const handleAddCurrency = async () => {
    if (!selectedCurrencies.length) {
      notification.warning({
        message: 'Uyarı',
        description: 'Lütfen en az bir para birimi seçin',
      });
      return;
    }

    try {
      const result = await createCurrency({
        data: {
          currencies: selectedCurrencies,
        },
      });

      if (result.result === 'success') {
        notification.success({
          message: 'Başarılı',
          description: 'Para birimleri başarıyla eklendi',
        });
        setIsModalOpen(false);
        setSelectedCurrencies([]);
        getBusinessCurrencies(); 
      }
    } catch (error) {
      notification.error({
        message: 'Hata',
        description: error.message || 'Para birimleri eklenirken bir hata oluştu',
      });
    }
  };

  const getAvailableCurrencies = () => {
    const existingCurrencyIds = new Set(businessCurrencies?.data?.map((c) => c.currency_id) || []);

    return allCurrencies?.data?.filter((currency) => !existingCurrencyIds.has(currency.currency_id)) || [];
  };

  if (businessCurrenciesLoading) {
    return <Spin size="large" className="center-spin" />;
  }

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <h2>Para Birimleri</h2>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => setIsModalOpen(true)}>
          Para Birimi Ekle
        </Button>
      </div>

      <Table
        dataSource={businessCurrencies?.data || []}
        columns={columns}
        rowKey="currency_id"
        pagination={{
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total) => `Toplam ${total} para birimi`,
        }}
      />

      <Modal
        title="Para Birimi Ekle"
        open={isModalOpen}
        onOk={handleAddCurrency}
        onCancel={() => {
          setIsModalOpen(false);
          setSelectedCurrencies([]);
        }}
        confirmLoading={createLoading}
      >
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder="Para birimleri seçin"
          loading={allCurrenciesLoading}
          value={selectedCurrencies}
          onChange={setSelectedCurrencies}
          options={getAvailableCurrencies().map((currency) => ({
            label: `${currency.label} (${currency.currency})`,
            value: currency.currency_id,
          }))}
          maxTagCount="responsive"
        />
      </Modal>
    </div>
  );
};

export default CurrencySettings;
